<template>
  <div class='teacher'>
    <list-template
        :current-page='page'
        :table-config='tableConfig'
        :search-config='searchConfig'
        :table-data='tableData'
        :total='total'
        :loading="loading"
        :isDisabled="{ exportData: !search}"
        @onChangePage='changeCurrentPage'
        @onHandle='tableHandle'
        @onReset='search = null'
        @onSearch='onSearch'
        @onExportData="onExportData"
    >
    </list-template>
    <el-dialog v-if='dialogVisible0' :visible.sync='dialogVisible0' title='辅导记录'>
      <el-form :model='formData' label-position='left' label-width='100rem' size='mini'>
        <el-form-item label='发起人'>
          <div class='title_item'>{{ formData.apply_method_name }}</div>
        </el-form-item>
        <el-form-item label='辅导科目'>
          <div class='title_item'>{{ formData.subject }}</div>
        </el-form-item>
        <el-form-item label='学生姓名'>
          <div v-for='item in formData.student' class='title_item'>{{ item }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='学生班级'>
          <div class='title_item'>{{ formData.class }}</div>
        </el-form-item>
        <el-form-item label='实际辅导开始时间' label-width='350rem'>
          <div class='title_item'>{{ formData.actual_start_at }}</div>
        </el-form-item>
        <el-form-item label='实际辅导结束时间' label-width='350rem'>
          <div class='title_item'>{{ formData.actual_end_at }}</div>
        </el-form-item>
        <el-form-item label='辅导地点'>
          <div class='title_item'>{{ formData.place }}</div>
        </el-form-item>
        <el-form-item label='辅导知识点'>
          <div v-for='item in formData.ability' class='title_item'>{{ item.name }}</div>
        </el-form-item>
        <el-form-item label='辅导时长'>
          <div class='title_item'>{{ formData.duration }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='辅导次数'>
          <div class='title_item'>{{ formData.coach_number }}</div>
        </el-form-item>
        <el-form-item label='辅导前留言'>
          <div class='title_item'>{{ formData.msg_to_student }}</div>
        </el-form-item>
        <el-form-item label='计划辅导开始时间' label-width='350rem'>
          <div class='title_item'>{{ formData.plan_start_at }}</div>
        </el-form-item>
        <el-form-item label='计划辅导结束时间' label-width='350rem'>
          <div class='title_item'>{{ formData.plan_end_at }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='危机状态'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.crisis_status }}</div>
        </el-form-item>
        <el-form-item label='下次咨询时间'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.next_coach_time }}</div>
        </el-form-item>
        <el-form-item label='本次咨询是否计划预约督导与专家同行讨论' label-width='400rem'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.need_professional }}</div>
        </el-form-item>
        <el-form-item label='督导师或专家/同行姓名' label-width='400rem'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.professional_name }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='计划督导问题/主题' label-width='400rem'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.main_problem }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='执业资格'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.certificate_level }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='对当事人情况的客观描述' label-width='400rem'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.teacher_description }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='评估分析'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.evaluate_analysis }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='危机干预过程'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.interpose_process }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='后续咨询计划'>
          <div v-if='formData.summary' class='title_item'>{{ formData.summary.follow_up_plan }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='我的笔记'>
          <div class='title_item'>{{ formData.note }}</div>
        </el-form-item>
        <el-form-item label='辅导状态'>
          <div class='title_item'>{{ formData.status }}</div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-if='dialogVisible1' :visible.sync='dialogVisible1' title='辅导记录'>
      <el-form :model='formData' label-position='left' label-width='100rem' size='mini'>
        <el-form-item label='发起人'>
          <div class='title_item'>{{ formData.apply_method_name }}</div>
        </el-form-item>
        <el-form-item label='辅导科目'>
          <div class='title_item'>{{ formData.subject }}</div>
        </el-form-item>
        <el-form-item label='学生姓名'>
          <div v-for='item in formData.student' class='title_item'>{{ item }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='学生班级'>
          <div class='title_item'>{{ formData.class }}</div>
        </el-form-item>
        <el-form-item label='实际辅导开始时间' label-width='350rem'>
          <div class='title_item'>{{ formData.actual_start_at }}</div>
        </el-form-item>
        <el-form-item label='实际辅导结束时间' label-width='350rem'>
          <div class='title_item'>{{ formData.actual_end_at }}</div>
        </el-form-item>
        <el-form-item label='辅导地点'>
          <div class='title_item'>{{ formData.place }}</div>
        </el-form-item>
        <el-form-item label='辅导知识点'>
          <div v-for='item in formData.ability' class='title_item'>{{ item.name }}</div>
        </el-form-item>
        <el-form-item label='辅导时长'>
          <div class='title_item'>{{ formData.duration }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='辅导次数'>
          <div class='title_item'>{{ formData.coach_number }}</div>
        </el-form-item>
        <el-form-item label='计划辅导开始时间' label-width='350rem'>
          <div class='title_item'>{{ formData.plan_start_at }}</div>
        </el-form-item>
        <el-form-item label='计划辅导结束时间' label-width='350rem'>
          <div class='title_item'>{{ formData.plan_end_at }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='我的笔记' label-width='200rem'>
          <div class='title_item'>{{ formData.note }}</div>
        </el-form-item>
        <el-form-item label='辅导状态'>
          <div class='title_item'>{{ formData.status }}</div>
        </el-form-item>
        <el-form-item label='辅导前留言'>
          <div class='title_item'>{{ formData.msg_to_student }}</div>
        </el-form-item>
        <el-form-item label='课堂专注度'>
          <div class='title_item'>{{
              formData.summary.focus_on == '1' ? '低' : formData.summary.focus_on == '2' ? "较低" : formData.summary.focus_on == '3' ? '一般' : formData.summary.focus_on == '4' ? '较高' : '高'
            }}
          </div>
        </el-form-item>
        <el-form-item label='情绪状态'>
          <div class='title_item'>{{
              formData.summary.emotion_status == '1' ? '低' : formData.summary.emotion_status == '2' ? "较低" : formData.summary.emotion_status == '3' ? '一般' : formData.summary.emotion_status == '4' ? '较高' : '高'
            }}
          </div>
        </el-form-item>
        <el-form-item label='笔记习惯'>
          <div class='title_item'>{{
              formData.summary.note_habit == '1' ? '差' : formData.summary.note_habit == '2' ? "较差" : formData.summary.note_habit == '3' ? '一般' : formData.summary.note_habit == '4' ? '较好' : '好'
            }}
          </div>
        </el-form-item>
        <el-form-item label='自主学习习惯'>
          <div class='title_item'>{{
              formData.summary.self_study == '1' ? '差' : formData.summary.self_study == '2' ? "较差" : formData.summary.self_study == '3' ? '一般' : formData.summary.self_study == '4' ? '较好' : '好'
            }}
          </div>
        </el-form-item>
        <el-form-item label='自我反思能力'>
          <div class='title_item'>{{
              formData.summary.introspect_ability == '1' ? '弱' : formData.summary.introspect_ability == '2' ? "较弱" : formData.summary.introspect_ability == '3' ? '一般' : formData.summary.introspect_ability == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='辅导效果'>
          <div class='title_item'>{{
              formData.summary.effect == '1' ? '差' : formData.summary.effect == '2' ? "较差" : formData.summary.effect == '3' ? '一般' : formData.summary.effect == '4' ? '较好' : '好'
            }}
          </div>
        </el-form-item>
        <el-form-item label='信息加工与整理' label-width='200'>
          <div class='title_item'>{{
              formData.summary.message_sort == '1' ? '弱' : formData.summary.message_sort == '2' ? "较弱" : formData.summary.message_sort == '3' ? '一般' : formData.summary.message_sort == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='逻辑推理与论证' label-width='200'>
          <div class='title_item'>{{
              formData.summary.logic_reason == '1' ? '弱' : formData.summary.logic_reason == '2' ? "较弱" : formData.summary.logic_reason == '3' ? '一般' : formData.summary.logic_reason == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='科学探究'>
          <div class='title_item'>{{
              formData.summary.scientific_explore == '1' ? '弱' : formData.summary.scientific_explore == '2' ? "较弱" : formData.summary.scientific_explore == '3' ? '一般' : formData.summary.scientific_explore == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='批判性思维'>
          <div class='title_item'>{{
              formData.summary.critical_thinking == '1' ? '弱' : formData.summary.critical_thinking == '2' ? "较弱" : formData.summary.critical_thinking == '3' ? '一般' : formData.summary.critical_thinking == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='语言表达'>
          <div class='title_item'>{{
              formData.summary.language_performance == '1' ? '弱' : formData.summary.language_performance == '2' ? "较弱" : formData.summary.language_performance == '3' ? '一般' : formData.summary.language_performance == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='动力来源'>
          <div class='title_item'>{{ formData.summary.study_power }}</div>
        </el-form-item>
        <el-form-item v-if='formData.summary.images' label='评价图片'>
          <div class='title_item'>
            <el-image v-for='item in formData.summary.images' :preview-src-list='srcList' :src='item'
                      style='width: 100rem; height: 100rem;margin-right: 10rem;' :z-index=4000>
            </el-image>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-if='dialogVisible2' :visible.sync='dialogVisible2' title='辅导记录'>
      <el-form :model='formData' label-position='left' label-width='100rem' size='mini'>
        <el-form-item label='发起人'>
          <div class='title_item'>{{ formData.apply_method_name }}</div>
        </el-form-item>
        <el-form-item label='辅导科目'>
          <div class='title_item'>{{ formData.subject }}</div>
        </el-form-item>
        <el-form-item label='学生姓名'>
          <div v-for='item in formData.student' class='title_item'>{{ item }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='学生班级'>
          <div class='title_item'>{{ formData.class }}</div>
        </el-form-item>
        <el-form-item label='实际辅导开始时间' label-width='350rem'>
          <div class='title_item'>{{ formData.actual_start_at }}</div>
        </el-form-item>
        <el-form-item label='实际辅导结束时间' label-width='350rem'>
          <div class='title_item'>{{ formData.actual_end_at }}</div>
        </el-form-item>
        <el-form-item label='辅导地点'>
          <div class='title_item'>{{ formData.place }}</div>
        </el-form-item>
        <el-form-item label='辅导知识点'>
          <div v-for='item in formData.ability' class='title_item'>{{ item.name }}</div>
        </el-form-item>
        <el-form-item label='辅导时长'>
          <div class='title_item'>{{ formData.duration }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='辅导次数'>
          <div class='title_item'>{{ formData.coach_number }}</div>
        </el-form-item>
        <el-form-item label='计划辅导开始时间' label-width='350rem'>
          <div class='title_item'>{{ formData.plan_start_at }}</div>
        </el-form-item>
        <el-form-item label='计划辅导结束时间' label-width='350rem'>
          <div class='title_item'>{{ formData.plan_end_at }}</div>
        </el-form-item>
        <el-form-item label='辅导前留言'>
          <div class='title_item'>{{ formData.msg_to_student }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_creator == 1' label='我的笔记' label-width='200rem'>
          <div class='title_item'>{{ formData.note }}</div>
        </el-form-item>
        <el-form-item label='辅导状态' label-width='200rem'>
          <div class='title_item'>{{ formData.status }}</div>
        </el-form-item>
        <el-form-item label='课堂专注度'>
          <div class='title_item'>{{
              formData.summary.focus_on == '1' ? '低' : formData.summary.focus_on == '2' ? "较低" : formData.summary.focus_on == '3' ? '一般' : formData.summary.focus_on == '4' ? '较高' : '高'
            }}
          </div>
        </el-form-item>
        <el-form-item label='情绪状态'>
          <div class='title_item'>{{
              formData.summary.emotion_status == '1' ? '低' : formData.summary.emotion_status == '2' ? "较低" : formData.summary.emotion_status == '3' ? '一般' : formData.summary.emotion_status == '4' ? '较高' : '高'
            }}
          </div>
        </el-form-item>
        <el-form-item label='笔记习惯'>
          <div class='title_item'>{{
              formData.summary.note_habit == '1' ? '差' : formData.summary.note_habit == '2' ? "较差" : formData.summary.note_habit == '3' ? '一般' : formData.summary.note_habit == '4' ? '较好' : '好'
            }}
          </div>
        </el-form-item>
        <el-form-item label='自主学习习惯'>
          <div class='title_item'>{{
              formData.summary.self_study == '1' ? '差' : formData.summary.self_study == '2' ? "较差" : formData.summary.self_study == '3' ? '一般' : formData.summary.self_study == '4' ? '较好' : '好'
            }}
          </div>
        </el-form-item>
        <el-form-item label='自我反思能力'>
          <div class='title_item'>{{
              formData.summary.introspect_ability == '1' ? '弱' : formData.summary.introspect_ability == '2' ? "较弱" : formData.summary.introspect_ability == '3' ? '一般' : formData.summary.introspect_ability == '4' ? '较强' : '强'
            }}
          </div>
        </el-form-item>
        <el-form-item label='辅导效果'>
          <div class='title_item'>{{
              formData.summary.effect == '1' ? '差' : formData.summary.effect == '2' ? "较差" : formData.summary.effect == '3' ? '一般' : formData.summary.effect == '4' ? '较好' : '好'
            }}
          </div>
        </el-form-item>
        <el-form-item label='动力来源'>
          <div class='title_item'>{{ formData.summary.study_power }}</div>
        </el-form-item>
        <el-form-item v-if='formData.summary.images' label='评价图片'>
          <div class='title_item'>
            <el-image v-for='(item, index) in formData.summary.images' :preview-src-list='srcList' :src='item' :key="index"
                      style='width: 100rem; height: 100rem;margin-right: 10rem;' :z-index=4000>
            </el-image>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-if='dialogVisible6' :visible.sync='dialogVisible6' title='辅导记录'>
      <el-form :model='formData' label-position='left' label-width='100rem' size='mini'>
        <el-form-item label='发起人'>
          <div class='title_item'>{{ formData.apply_method_name }}</div>
        </el-form-item>
        <el-form-item label='辅导科目'>
          <div class='title_item'>{{ formData.subject }}</div>
        </el-form-item>
        <el-form-item label='学生姓名'>
          <div v-for='item in formData.student' class='title_item'>{{ item }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='学生班级'>
          <div class='title_item'>{{ formData.class }}</div>
        </el-form-item>
        <el-form-item label='辅导状态' label-width='400rem'>
          <div class='title_item'>{{ formData.status }}</div>
        </el-form-item>
        <el-form-item label='拒绝原因' label-width='400rem'>
          <div class='title_item'>{{ formData.refuse_reason }}</div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-if='dialogVisible7' :visible.sync='dialogVisible7' title='辅导记录'>
      <el-form :model='formData' label-position='left' label-width='100rem' size='mini'>
        <el-form-item label='发起人'>
          <div class='title_item'>{{ formData.apply_method_name }}</div>
        </el-form-item>
        <el-form-item label='辅导科目'>
          <div class='title_item'>{{ formData.subject }}</div>
        </el-form-item>
        <el-form-item label='学生姓名'>
          <div v-for='item in formData.student' class='title_item'>{{ item }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='学生班级'>
          <div class='title_item'>{{ formData.class }}</div>
        </el-form-item>
        <el-form-item label='辅导状态' label-width='400rem'>
          <div class='title_item'>{{ formData.status }}</div>
        </el-form-item>
        <el-form-item label='取消原因' label-width='400rem'>
          <div class='title_item'>{{ formData.refuse_reason }}</div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-if='dialogVisible8' :visible.sync='dialogVisible8' title='辅导记录'>
      <el-form :model='formData' label-position='left' label-width='100rem' size='mini'>
        <el-form-item label='发起人'>
          <div class='title_item'>{{ formData.apply_method_name }}</div>
        </el-form-item>
        <el-form-item label='辅导科目'>
          <div class='title_item'>{{ formData.subject }}</div>
        </el-form-item>
        <el-form-item label='学生姓名'>
          <div v-for='item in formData.student' class='title_item'>{{ item }}</div>
        </el-form-item>
        <el-form-item v-if='formData.is_coach_number == 1' label='学生班级'>
          <div class='title_item'>{{ formData.class }}</div>
        </el-form-item>
        <el-form-item label='辅导状态'>
          <div class='title_item'>{{ formData.status }}</div>
        </el-form-item>
        <el-form-item label='实际辅导时长'>
          <div class='title_item'>{{ formData.duration }}</div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import tools from "utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:false,
      srcList: [],
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'teacher',
          placeholder: '搜索教师'
        },{
          prop: 'start_end_date',
          placeholder: '筛选时间',
          tag: 'datePicker',
          property: {
            clearable: false,
            type: 'daterange',
            size: 'small',
            style: 'margin-right:20rem;',
            rangeSeparator: '-',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          },
        },
        {
          prop: 'coach_status',
          tag: 'select',
          placeholder: '筛选辅导状态',
          options: [],
          label: 'name',
          value: 'status'
        },
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '筛选学校',
          options: [],
          label: 'school_name',
          value: 'id'
        },
        {
          prop: 'grade_id',
          tag: 'select',
          placeholder: '筛选年级',
          options: [],
          label: 'name',
          value: 'id'
        },
        {
          prop: 'subject_id',
          tag: 'select',
          placeholder: '筛选学科',
          options: [],
          label: 'subject_name',
          value: 'id'
        },
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'teacher',
          label: '辅导教师'
        },
        {
          prop: 'subject',
          label: '辅导学科'
        },
        {
          prop: 'actual_end_at',
          label: '实际结束时间',
        },
        {
          prop: 'place',
          label: '辅导地点'
        },
        {
          prop: 'status',
          label: '辅导状态'
        },
        {
          prop: 'grade',
          label: '年级'
        },
        {
          prop: 'school',
          label: '校区'
        },
        {
          label: '操作',
          handle: true,
          width: '100',
          buttons: [{ type:"view", text:"查看" }]
        }
      ],
      search: null,
      total: 0,
      // 表格中的数据
      tableData: [],
      statisticalNumber: null,
      dialogVisible0: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      Germanshow: false,
      mentalitShow: false,
      subjectShow: false,
      Title: '',
      formData: {
        summary: {
          images: [],
        }
      }
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      if (search?.start_end_date){
        search.start_at = search.start_end_date[0]
        search.end_at = search.start_end_date[1]
      }
      this.loading = true;
      this.$_axios2.get('api/1v1/record', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.data
        this.total = res.data.data.total
      }).finally(()=>this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row, text, index) {
      this.$_axios2.get('api/1v1/record/' + row.id).then(res => {
        this.formData = res.data.data
        if (res.data.data.summary) {
          this.srcList = this.formData.summary.images
        } else this.srcList = []

        if (this.formData.summary == null) {
          this.formData.summary = {
            images: []
          }
        }
      })
      this.Title = row.student
      if (row.coach_state === 0) {
        this.dialogVisible0 = true
      }
      if (row.coach_state === 1) {
        this.dialogVisible1 = true
      }
      if (row.coach_state === 2) {
        this.dialogVisible2 = true
      }
      if (row.coach_state === 6) {
        this.dialogVisible6 = true
      }
      if (row.coach_state === 7) {
        this.dialogVisible7 = true
      }
      if (row.coach_state === 8) {
        this.dialogVisible8 = true
      }
    },
    onExportData(){
      let {search} = this
      if (search?.start_end_date){
        search.start_at = search.start_end_date[0]
        search.end_at = search.start_end_date[1]
      }
      this.$_axios2.get('/api/1v1/record/download', {params: search}).then(res => {
        tools.download('一对一辅导记录' ,res.data)
        console.log(res.data, 'res.data', '----------------')
      })
    }
  },
  created() {
    this.$_axios2.get('api/1v1/record/get-coach-status').then(res => {
      this.searchConfig[2].options = res.data.data
    })
    this.$_axios.get('site/fist-subject').then(res => {
      this.searchConfig[5].options = res.data.data
    })
    this.$utils.commonApi.getGradeList().then(res => {
      this.searchConfig[4].options = res.data.data
    })
    this.$_axios.get('site/school').then(res => {
      this.searchConfig[3].options = res.data.data
    })
  }
}
</script>

<style lang='scss' scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }

}

.title {
  font-size: 20rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20rem;
}

.title_item {
  text-align: right;
}
</style>
